<template>
	<div>
		<div class="sub-tit"><p>하이프로 기술인재 평가</p></div>
		<!-- 인재평가 항목 -->
		<div class="Board type3 admin">
			<table class="Board_type3 hr_evl">
				<colgroup>
					<col width="13%">
					<col width="11%">
					<col width="57%">
					<col width="4%">
					<col width="4%">
					<col width="4%">
					<col width="4%">
					<col width="4%">
				</colgroup>
				<thead>
					<tr>
						<th colspan="2">항 목</th>
						<th>내 용</th>
						<th class="rank" v-for="(grade, grdIdx) in gradeList" :key="grdIdx">{{grade}}<span>({{10 - grdIdx}}점)</span></th>
					</tr>
				</thead>
				<tbody>
					<!-- 태도 -->
					<tr v-for="(row, rowIdx) in pronEvalList" :key="rowIdx">
						<td class="score" :rowspan="row.mergeCnt" v-if="row.mergeCnt == row.mergeChkCnt"><SelectComp type="text" cdId="PRO110" v-model="row.evalItemDivCd" /></td>
						<td class="score"><SelectComp type="text" cdId="PRO111" v-model="row.evalItemCd" /></td>
						<td>{{row.evalItemCont}}</td>
						<td class="score" v-for="i in 5" :key="i">
							<input type="radio" :id="row.evalItemSeq + '_' + i" :value="11 - i" v-model="row.evalScore" @change="rdoChk()">
							<label :for="row.evalItemSeq + '_' + i">
								<span></span>
							</label>
						</td>
					</tr>					
					<!-- 합계 -->
					<tr class="sum">
						<td class="score" colspan="2">합 계({{pronEvalList.length * 10}})</td>
						<td class="score sum" colspan="6">{{pronEval.totScore}}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<!-- 평가의견 -->
		<div class="Board type3 admin opinion">
			<table class="Board_type3 hr_evl">
				<colgroup>
					<col width="9%">
					<col width="15%">
					<col width="75%">
				</colgroup>
				<tbody>
					<tr>
						<td class="score" rowspan="4">평<br/>가<br/>의<br/>견</td>
						<td class="score">태도측면</td>
						<td colspan="6" class="textarea">
							<InputComp type="textarea" classNm="evl" v-model="pronEval.attitudOpinCont" />
						</td>
					</tr>
					<tr>
						<td class="score">업무능력측면</td>
						<td colspan="6" class="textarea">
							<InputComp type="textarea" classNm="evl" v-model="pronEval.workAbilOpinCont" />
						</td>
					</tr>
					<tr>
						<td class="score">개발기술측면</td>
						<td colspan="6" class="textarea">
							<InputComp type="textarea" classNm="evl" v-model="pronEval.techIntegAbilOpinCont" />
						</td>
					</tr>
					<tr>
						<td class="score">종합의견</td>
						<td colspan="6" class="textarea">
							<InputComp type="textarea" classNm="evl" v-model="pronEval.overallOpinCont" />
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<!-- 하단 버튼 -->
		<div class="select_btn">
			<div class="btn_01" @click="btnCancel()">취소</div>
			<div class="btn_02" @click="btnSubmit()">하이프로 평가 확정</div>
		</div>
		
	</div>
</template>

<script>
export default {
	data() {
		return {
			tecMberSeq : this.$route.query.tecMberSeq,
			
			pronEval : {
				attitudOpinCont : '',
				workAbilOpinCont : '',
				techIntegAbilOpinCont : '',
				overallOpinCont : '',
				totScore : 0,
			},
			pronEvalList : [],

			prfData : {},
			gradeList : ['A','B','C','D','E'],
		}
	},

	mounted() {
		this.getPronEvalList();
	},

	methods : {
		// 하이프로 평가 목록 조회
		getPronEvalList() {
			this.$.httpPost('/api/mem/adm/tec/getPronEvalList', {tecMberSeq : this.tecMberSeq})
				.then(res => {
					//console.log('getPronEvalList RESULT', res);

					if(res.data.pronEval) this.pronEval = res.data.pronEval;
					this.pronEvalList = res.data.pronEvalList;
					this.$emit('searchEnd', res.data);
				}).catch(this.$.httpErrorCommon);
		},

		// 점수 체크
		rdoChk() {
			var sumScore = 0;

			for(var i in this.pronEvalList) {
				if(this.pronEvalList[i].evalScore) sumScore += this.pronEvalList[i].evalScore;
			}

			this.pronEval.totScore = sumScore;
		},

		// 취소버튼
		btnCancel() {
			if(confirm('취소하시겠습니까?')) {
				//this.$router.push({name:'MEM912M02', query:{tecMberSeq:this.tecMberSeq}});
				if(this.$route.params.histCnt && this.$route.params.histCnt > 1){
					this.$router.go(1 - this.$route.params.histCnt);	
				} else {
					this.$router.go(-1);
				}
			}
		},

		// 등록
		btnSubmit() {
			var tempAry = [];
			var tempObj = {};
			
			for(var i in this.pronEvalList) {

				if(!this.pronEvalList[i].evalScore) {
					alert('평가하지 않은 항목이 있습니다.');
					return false;
				}

				if(i == 0) {
					tempObj.evalItemDivCd = this.pronEvalList[i].evalItemDivCd;
					tempObj.partSum = this.pronEvalList[i].evalScore;
					tempObj.partCnt = this.pronEvalList[i].mergeCnt;
				}else if(this.pronEvalList[i].evalItemDivCd != this.pronEvalList[i - 1].evalItemDivCd) {
					tempAry.push(tempObj);

					tempObj = {};
					tempObj.evalItemDivCd = this.pronEvalList[i].evalItemDivCd;
					tempObj.partSum = this.pronEvalList[i].evalScore;
					tempObj.partCnt = this.pronEvalList[i].mergeCnt;
				}else if(i == this.pronEvalList.length - 1) {
					tempObj.partSum += this.pronEvalList[i].evalScore;
					tempAry.push(tempObj);
				}else {
					tempObj.partSum += this.pronEvalList[i].evalScore;
				}
			}

			var param = {};
			param.tecMberSeq = this.tecMberSeq;
			param.gradeAry = tempAry;

			//console.log('getPronEvalData param', param);

			this.$.popup('/adm/mem/MEM912P04', param)
				.then(res => {
					if(res) this.setPronEvalList();
				});
		},

		setPronEvalList() {
			var param = this.pronEval;
			param.tecMberSeq = this.tecMberSeq;
			param.pronEvalList = this.pronEvalList;

			this.$.httpPost('/api/mem/adm/tec/setPronEvalList', param)
				.then(() => {
					alert('하이프로 평가 등록이 완료되었습니다.');
					this.$router.go();
				}).catch(this.$.httpErrorCommon);
		}

	}
}
</script>